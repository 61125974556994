import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { parseQueryString } from '../utils/helper'
import { DESTINATIONS } from '../utils/constants'
import DestinationHeader from '../component/page_component/DestinationHeader'
import { Helmet } from 'react-helmet'
import hreflangConfig from '../utils/hreflangConfig'
import countriesData from '../data/countriesData.json'
import departmentData from '../data/departmentData.json'
import SeoContent from '../component/page_component/SeoContent'
import Faq from '../component/page_component/faq'
import { SearchContext } from '../contexts/searchContext'
import ContactForm from '../component/page_component/contact/contactForm'
import Destination from '../component/page_component/Destination'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'
import { useLocation } from '@reach/router'

parseQueryString
const Buy: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data, region, departement, country },


  } = props


  const getNestedData = (data: any, lang: string, key: string) => {

    return data?.[lang]?.[key] || null;
  };
  //const region = location?.pathname.split('/')[2]
  const intl = useIntl()
  let propsData;
  let image = "default_main.jpg"

  if (departement) {
    image = DESTINATIONS[departement.toLowerCase() as keyof typeof DESTINATIONS]?.img || "default_main.jpg"
    propsData = getNestedData(departmentData, intl.locale, departement)

  }

  else if (country) {
    image = DESTINATIONS[country.toLowerCase() as keyof typeof DESTINATIONS]?.img || "default_main.jpg"
    propsData = getNestedData(countriesData, intl.locale, country)
  }




  const faq = propsData?.content?.faq || [];
  const sections = propsData?.content?.sections || [];
  const title = propsData?.content?.title || '';
  const subtitle = propsData?.content?.description || '';

  let destinationHeaderProps = {
    src: '',
    alt: '',
    title: '',
    detail: '',
    btnTxt: '',
    tags: '',
  }



  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`

  const valuesToCheck = [
    'paris-je-taime',
    'normandie-cherie',
    'loccitanie-terre-de-soleil',
    'la-cote-atlantique',
    'mediterranee-mon-amour',
    'a-la-montagne',
    'beaute-des-iles',
    'le-nouveau-portugal',
    'london-calling',
    'big-in-usa',
    'dubai',
  ]

  let foundValue = ''

  valuesToCheck.forEach((value) => {
    if (canonicalUrl.includes(value)) {
      foundValue = value
    }
  })

  const loc = useLocation();


  const { setSearch, flushSearchState } = useContext(SearchContext)
  useEffect(() => {
    if (loc?.hash === "#no-update") {

    } else {
      flushSearchState()
    }

    let location = []


    if (country) {

      let countryItem = {
        category: 'COUNTRY',
        value: country,
        count: 0,
        label: country,
        counts: 0,
        tags: [country]
      }
      setSearch({ name: 'country', value: [countryItem] })

    }

    if (region) {
      let regionItem = {
        category: 'REGION',
        value: region,
        count: 0,
        label: region,
        counts: 0,
        tags: [region]
      }
      location.push(regionItem)

    }
    if (departement) {
      let departementItem = {
        category: 'TOWN',
        value: departement,
        count: 0,
        label: departement,
        counts: 0,
        tags: [departement]
      }
      location.push(departementItem)
    }

    setSearch({ name: 'location', value: location })
    setSearch({ name: 'transactionType', value: 'buy' })


  }, [loc?.hash])

  const hreflangLinks = foundValue
    ? hreflangConfig[`/fr/${foundValue}/` as keyof typeof hreflangConfig]
    : {}
  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      title={destinationHeaderProps.title + ' - Kretz'}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          foundValue &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="Destinations de Rêve - Kretz" />
      </Helmet>
      {/* <DestinationHeader {...destinationHeaderProps} /> */}
      <Grid container justifyContent="center" id="buy-region-dream-destination">
        <PropertyList
          filterBackgroundColor={'#fff'}
          data={data}
          location={location}
          title={title}
          description={subtitle}
          image={image}
          withFilter={true}
          disableCountryFilter={true}
          disableCityFilter={(region || departement) ? true : false}
        // image={LIFESTYLE[currentType.toLowerCase() as keyof typeof LIFESTYLE]?.mainImg || ''}

        />
      </Grid>
      <Destination pays={departement ? "" : country} />
      <ContactForm
        title={intl.formatMessage({ id: 'new.Formulaire de contact' })}
        subtitle={intl.formatMessage({ id: 'new.Résultats de recherche_4' })}
        backgroundColor="#fff"
      />
      <SeoContent sections={sections}></SeoContent>
      <Faq items={faq} />
      <PreFooterMenu />
    </Layout>
  )
}

export default Buy
