import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { CustomButton } from '../../CustomButton'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import IconButton from '@material-ui/core/IconButton'
import CustomIconButton from '../../shared/components/CustomIconButton'

const useStyles = makeStyles((theme) => ({
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    marginBlock: '1rem',
    paddingTop: '1.5rem',
    paddingRight: '2rem',
    gap: '0.5rem',
    [theme.breakpoints.between('md', 'md')]: {
      paddingRight: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0rem',
      display: 'none',
    },
  },
}))

interface CustomButtonGroupProps {
  next?: () => void
  previous?: () => void
}

const DestinationSliderOutsideButton: React.FC<CustomButtonGroupProps> = ({ next, previous }) => {
  const classes = useStyles()

  // Affichage par défaut en mode horizontal
  return (
    <div className={classes.buttonGroupContainer}>
      <CustomIconButton icon={<ArrowBackIcon />} onClick={previous} />
      <CustomIconButton icon={<ArrowForwardIcon />} position="right" onClick={next} />
    </div>
  )
}

export default DestinationSliderOutsideButton
